import React from 'react';
import PropTypes from 'prop-types';
import ActivismBlockInfo from '../../../02_molecules/ActivismBlockInfo';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';
import DefaultPicture, { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import BulletPointBackgroundIcon from '../../../../public/static/icons/bullet-point-background.svg';

import styles from './index.module.scss';

const BBActivismFourTile = ({
  variant = 'blue',
  label = '',
  title = '',
  subtitle = '',
  items,
  behaviorSettings = null,
  uuid = null,
}) => {
  const classes = [
    'bb',
    styles['bb-activism-four-tile'],
    styles[`bb-activism-four-tile--variant-${variant}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div className={classes.join(' ')} id={uuid}>
      {label && (
        <div className="container">
          <div className={styles['bb-activism-four-tile__label']}>{label}</div>
        </div>
      )}

      <ActivismBlockInfo title={title} subtitle={subtitle} size="large" />

      <div className={`container ${styles['bb-activism-four-tile__items-list']}`}>
        {items.map((item) => (
          <div key={item.number} className={styles['bb-activism-four-tile__item']}>
            <div className={styles['bb-activism-four-tile__item-image']}>
              <DefaultPicture {...item.image} />
            </div>
            <div className={styles['bb-activism-four-tile__item-info']}>
              <div className={styles['bb-activism-four-tile__item-title-group']}>
                <div className={styles['bb-activism-four-tile__item-number']}>
                  <BulletPointBackgroundIcon
                    className={styles['bb-activism-four-tile__item-number-bg']}
                  />
                  {item.number}
                </div>
                <div className={styles['bb-activism-four-tile__item-title']}>{item.title}</div>
              </div>

              {item.description && (
                <div className={styles['bb-activism-four-tile__item-description']}>
                  {item.description}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

BBActivismFourTile.propTypes = {
  variant: PropTypes.oneOf(['blue', 'yellow']).isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape(picturePropTypes).isRequired,
      number: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBActivismFourTile;
