import React from 'react';
import PropTypes from 'prop-types';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../../utils/behaviorSettings';
import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import FormattedText from '../../../../01_atoms/FormattedText';

import styles from './index.module.scss';

const BBActivismCTAWithBackground = ({
  variant = 'blue',
  button,
  title,
  text = '',
  behaviorSettings = null,
  uuid = null,
}) => {
  const classes = [
    'bb',
    styles['bb-activism-cta-with-background'],
    styles[`bb-activism-cta-with-background--variant-${variant}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  const isPink = variant === 'pink';

  return (
    <div className={classes.join(' ')} id={uuid} data-component="bb-activism-cta-with-background">
      <div className="container">
        <div className={styles['bb-activism-cta-with-background__content']}>
          <div className={styles['bb-activism-cta-with-background__top-group']}>
            <div className={styles['bb-activism-cta-with-background__title']}>{title}</div>
            <Link {...button.nextLink}>
              <Button
                tag="a"
                isOutlined={!isPink}
                type={isPink ? 'activism-yellow' : 'activism-black'}
                href={button.nextLink.url}
                isExternal={button.isExternal}
                target={button.isExternal ? '_blank' : undefined}
              >
                {button.label}
              </Button>
            </Link>
          </div>

          {text && (
            <FormattedText
              className={styles['bb-activism-cta-with-background__text']}
              text={text}
            />
          )}
        </div>
      </div>
    </div>
  );
};

BBActivismCTAWithBackground.propTypes = {
  variant: PropTypes.oneOf(['blue', 'yellow', 'pink']).isRequired,
  title: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
    isExternal: PropTypes.bool,
  }).isRequired,
  text: PropTypes.string,
  uuid: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
};

export default BBActivismCTAWithBackground;
